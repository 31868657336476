import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form"]
  static values = {
    formId: { type: String, default: "new-transaction-form" }
  }

  show() {
    const form = document.getElementById(this.formIdValue);
    if (form) {
      form.classList.remove("is-hidden");
    }
  }

  hideAll() {
    const forms = document.querySelectorAll(".new-form");
    forms.forEach(form => form.classList.add("is-hidden"));
  }
}
