import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form", "display", "input", "select"]

  connect() {
    // Hide inputs initially
    this.inputTargets.forEach(input => input.style.display = 'none');
    
    // Add click event listeners to radio buttons
    this.element.querySelectorAll('.transaction-type-radio').forEach(radio => {
      radio.addEventListener('change', (e) => this.save(e));
    });

    // Initialize currency selects
    this.initializeCurrencySelects();
  }

  initializeCurrencySelects() {
    this.selectTargets.forEach(select => {
      if (select.name.includes('currency')) {
        const choices = new Choices(select, {
          searchEnabled: true,
          searchFields: ['label'],
          itemSelectText: '',
          classNames: {
            containerOuter: 'choices currency-select',
          },
          callbackOnCreateTemplates: function(template) {
            return {
              group: (classNames, data) => {
                return template(`
                  <div class="choices__group" data-group>
                    <div class="choices__heading">${data.label}</div>
                    ${data.items.map(choice => `
                      <div class="choices__item choices__item--choice" data-value="${choice.value}">
                        ${choice.label}
                      </div>
                    `).join('')}
                  </div>
                `);
              }
            };
          }
        });

        // Handle currency change
        choices.passedElement.element.addEventListener('change', (e) => {
          this.save(e);
        });
      }
    });
  }

  async save(event) {
    event.preventDefault();
    const formElement = event.target.closest('form');
    if (!formElement) return;

    // For radio buttons, update the active state immediately
    if (event.target.classList.contains('transaction-type-radio')) {
      const transactionId = event.target.dataset.transactionId;
      const toggleLabels = formElement.querySelectorAll('.toggle-label');
      toggleLabels.forEach(label => {
        const forId = label.getAttribute('for');
        const radio = document.getElementById(forId);
        if (radio) {
          label.classList.toggle('active', radio.checked);
        }
      });
    }

    try {
      const response = await fetch(formElement.action, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
          Accept: "application/json",
        },
        body: new FormData(formElement),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const data = await response.json();

      if (data.success) {
        this.notifySuccess("Changes saved successfully");
      }
    } catch (error) {
      console.error("Error saving change:", error);
      this.notifyError("Failed to save changes");
    }
  }

  showInput(event) {
    const display = event.currentTarget;
    const input = display.nextElementSibling;
    
    display.style.display = 'none';
    input.style.display = 'flex'; // Changed to flex for the input group
    input.querySelector('input').focus();
  }

  hideInput(event) {
    // If the new target is within the same input group, don't hide
    const inputGroup = event.currentTarget.closest('.amount-input-group');
    const display = inputGroup?.previousElementSibling;

    if (!inputGroup || !display) return;
    
    // Check if we're moving to another element within the same input group
    if (event.relatedTarget && inputGroup.contains(event.relatedTarget)) {
      return;
    }
    
    inputGroup.style.display = 'none';
    display.style.display = 'inline-block';
  }

  notifySuccess(message) {
    // Implement your preferred notification method
    // For example, using Bulma's notification component
    this.dispatch("notification", {
      type: "success",
      message: message,
    });
  }

  notifyError(message) {
    this.dispatch("notification", {
      type: "error",
      message: message,
    });
  }
}
