import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["content", "icon", "panel"];

  connect() {
    this.initializePanel();
  }

  initializePanel() {
    const startCollapsed = !this.contentTarget.hasAttribute(
      "data-start-expanded"
    );
    this.setCollapsedState(startCollapsed);
  }

  toggle() {
    this.setCollapsedState(
      !this.contentTarget.classList.contains("is-collapsed")
    );
  }

  setCollapsedState(collapsed) {
    this.contentTarget.classList.toggle("is-collapsed", collapsed);
    this.iconTarget.classList.toggle("fa-chevron-down", collapsed);
    this.iconTarget.classList.toggle("fa-chevron-up", !collapsed);
  }
}
