import ApplicationController from "./application_controller";
import i18n from "../config/i18n";
import { DirectUpload } from "@rails/activestorage";

export default class extends ApplicationController {
  static targets = [
    "amountDisplay",
    "dateDisplay",
    "descriptionDisplay",
    "inputGroup",
    "attachmentSignedId",
    "newTransactionForm",
  ];

  connect() {
    StimulusReflex.register(this);
    requestAnimationFrame(() => {
      this.hideAllInputs();
    });
    document.addEventListener("turbo:load", () => this.hideAllInputs());

    // Add form change event listener
    this.element.addEventListener("change", this.handleFormChanges.bind(this));
    this.scrollToUpdatedTransaction();

    if (this.hasNewTransactionFormTarget) {
      this.newTransactionFormTarget
        .querySelectorAll("input, textarea, select")
        .forEach((input) => {
          ["change", "input"].forEach((event) => {
            input.addEventListener(event, () =>
              this.validateNewTransactionForm(this.newTransactionFormTarget)
            );
          });
        });
    }
  }

  disconnect() {
    document.removeEventListener("turbo:load", () => this.hideAllInputs());
    this.element.removeEventListener(
      "change",
      this.handleFormChanges.bind(this)
    );
  }

  showInput(event) {
    event.stopPropagation();
    this.hideAllInputs();

    const display = event.currentTarget;
    const inputGroup = display.nextElementSibling;

    if (!inputGroup) return;

    // Store initial values
    const inputs = inputGroup.querySelectorAll("input, select, textarea");
    this.initialValues = Array.from(inputs).reduce((values, input) => {
      values[input.name] = input.value;
      return values;
    }, {});

    display.style.display = "none";
    inputGroup.style.display = "block";
    inputGroup.querySelector("input")?.focus();
  }

  hideAllInputs() {
    this.inputGroupTargets.forEach((group) => {
      if (group) {
        group.style.display = "none";
        const display = group.previousElementSibling;
        if (display) {
          display.style.display = "inline-block";
        }
      }
    });
  }

  cancelEdit(event) {
    event.preventDefault();
    const inputGroup = event.target.closest(
      "[data-transactions-target='inputGroup']"
    );
    const inputs = inputGroup.querySelectorAll("input, select, textarea");

    // Reset to initial values
    inputs.forEach((input) => {
      input.value = this.initialValues?.[input.name] || "";
    });

    this.hideInput(inputGroup);
  }

  saveEdit(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const inputGroup = button.closest(
      "[data-transactions-target='inputGroup']"
    );
    const editType = inputGroup.dataset.editType;
    const form = inputGroup.closest("form");

    if (form && !form.dataset.processing) {
      form.dataset.processing = true;
      this.showSpinner(button);
      this.stimulate("Transaction#update", form).catch(() => {
        delete form.dataset.processing;
        this.hideSpinner(button);
      });
    }
  }

  hideInput(inputGroup) {
    inputGroup.style.display = "none";
    const display = inputGroup.previousElementSibling;
    if (display) {
      display.style.display = "inline-block";
    }
  }

  // Add handleFormChanges method back
  handleFormChanges(event) {
    if (event.target.matches('input[type="radio"]')) {
      this.updateTypeToggleState(event.target);
    }
  }

  updateTypeToggleState(radio) {
    const typeToggle = radio.closest(".type-toggle");
    if (!typeToggle) return;

    typeToggle.querySelectorAll(".toggle-label").forEach((label) => {
      label.classList.remove("active");
    });

    const selectedLabel = typeToggle.querySelector(`label[for="${radio.id}"]`);
    if (selectedLabel) {
      selectedLabel.classList.add("active");
    }
  }

  scrollToUpdatedTransaction() {
    const updatedTransaction = document.querySelector(
      '.transaction-card[data-updated="true"]'
    );
    if (updatedTransaction) {
      updatedTransaction.scrollIntoView({ behavior: "auto", block: "center" });
      // Remove the flag after scrolling
      updatedTransaction.removeAttribute("data-updated");
    }
  }

  confirmDelete(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const transactionId = button.dataset.transactionId;

    if (!transactionId) return;

    if (confirm(i18n.t("confirmations.delete.transaction"))) {
      this.showSpinner(button);
      this.stimulate("Transaction#delete", button).catch(() => {
        this.hideSpinner(button);
      });
    }
  }

  handleFileUpload(event) {
    const input = event.target;
    const file = input.files[0];
    const fileLabel = input.closest(".file-label");

    if (!file) return;

    this.showSpinner(fileLabel);
    this.uploadFile(file, input, fileLabel);
  }

  uploadFile(file, input, button) {
    const url = input.dataset.directUploadUrl;
    const upload = new DirectUpload(file, url);

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
        this.hideSpinner(button);
      } else {
        const form = input.closest("form");
        input.value = "";

        let attachmentSignedId = form.querySelector(
          'input[name="transaction[attachment_signed_id]"]'
        );
        if (!attachmentSignedId) {
          attachmentSignedId = document.createElement("input");
          attachmentSignedId.type = "hidden";
          attachmentSignedId.name = "transaction[attachment_signed_id]";
          form.appendChild(attachmentSignedId);
        }
        attachmentSignedId.value = blob.signed_id;

        this.stimulate("Transaction#update", form).catch(() => {
          this.hideSpinner(button);
        });
      }
    });
  }

  removeAttachment(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const form = button.closest("form");

    if (
      form &&
      !form.dataset.processing &&
      confirm(i18n.t("confirmations.delete.attachment"))
    ) {
      form.dataset.processing = true;
      this.showSpinner(button);

      let attachmentSignedId = form.querySelector(
        'input[name="transaction[attachment_signed_id]"]'
      );
      if (attachmentSignedId) {
        attachmentSignedId.value = "";
      }

      this.stimulate("Transaction#update", form).catch(() => {
        delete form.dataset.processing;
        this.hideSpinner(button);
      });
    }
  }

  hasTarget(name) {
    return this.targets.find((target) => target.name === name) !== undefined;
  }

  showSpinner(element) {
    const icon = element.querySelector('[class*="fa-"]:not(.fa-spin)');
    const spinner = element.querySelector(".fa-spinner");
    if (icon && spinner) {
      icon.classList.add("is-hidden");
      spinner.classList.remove("is-hidden");
    }
  }

  hideSpinner(element) {
    const icon = element.querySelector('[class*="fa-"]:not(.fa-spin)');
    const spinner = element.querySelector(".fa-spinner");
    if (icon && spinner) {
      icon.classList.remove("is-hidden");
      spinner.classList.add("is-hidden");
    }
  }

  handleNewTransactionSubmit(event) {
    event.preventDefault();
    if (!this.isFormValid(event.target) || this.isProcessing) return;

    const button = event.target.querySelector('button[type="submit"]');
    this.showSpinner(button);
    this.stimulate("Transaction#create", event.target, {
      serializeForm: true,
    }).catch(() => {
      this.hideSpinner(button);
    });
  }

  cancelNew() {
    this.hideNewTransactionForm();
    const form = this.element.querySelector(".transaction-new-form");
    if (form) form.reset();
  }

  hideNewTransactionForm() {
    const form = document.querySelector("#new-transaction-form");
    if (form) form.classList.add("is-hidden");
  }

  showNewTransactionForm() {
    const form = document.querySelector("#new-transaction-form");
    if (form) {
      form.classList.remove("is-hidden");
      form.querySelector("input:not([disabled])").focus();
    }
  }

  isFormValid(form) {
    const requiredInputs = form.querySelectorAll("[required]");
    return Array.from(requiredInputs).every((input) => {
      if (input.type === "radio") {
        return form.querySelector(`input[name="${input.name}"]:checked`);
      }
      if (
        input.type === "number" &&
        input.name === "transaction[origin_amount]"
      ) {
        return parseFloat(input.value) > 0;
      }
      return input.value.trim() !== "";
    });
  }

  validateNewTransactionForm(form) {
    if (!form) return;

    const submitButton = form.querySelector('button[type="submit"]');
    if (submitButton) {
      submitButton.disabled = !this.isFormValid(form);
    }
  }
}
